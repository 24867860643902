import ___FuseJS from '../../../node_modules/fuse.js/dist/fuse.esm.js';
	
window.Fuse = window.Fuse || ___FuseJS;

/**
* Splits a string into an array of words, preserving words enclosed in quotes.
*
* @param {string} str - The input string to be split.
* @returns {string[]} - An array of words extracted from the input string.
*
* @example
* split('foo bar'); // ['foo', 'bar']
* split('foo "bar asd"'); // ['foo', 'bar asd']
* split('"foo bar"'); // ['foo bar']
*/
function splitSentencetoSegments( str ) {
	// Initialize an empty array to store the resulting words
	let result = [];
	// Initialize an empty string to store the current word being built
	let currentWord = '';
	// Initialize a flag to track whether we're currently inside a quoted string
	let inQuotes = false;
	
	// Loop through each character in the input string
	for (let i = 0; i < str.length; i++) {
		const char = str[i];
		
		// If the current character is a quote, toggle the inQuotes flag
		if (char === '"') {
			inQuotes = !inQuotes;
		}
		// If the current character is a space and we're not inside a quoted string,
		// add the currentWord to the result array and reset currentWord
		else if (char === ' ' && !inQuotes) {
			if (currentWord !== '') {
				result.push(currentWord);
				currentWord = '';
			}
		}
		// Otherwise, append the current character to the currentWord
		else {
			currentWord += char;
		}
	}
	
	// If there's still a word in currentWord, add it to the result array
	if (currentWord !== '') {
		result.push(currentWord);
	}
	
	// Return the resulting array of words
	return result;
}

export class SearchResultsHandler {
    
    target_items;

    search_result_views = {};

    default_search_result_view = {
        visible: false,
        extended: false 
    };

	track_by;
	gettextCatalog;

    constructor( track_by = "uid", gettextCatalog = null ) {
		this.track_by = track_by;
		this.gettextCatalog = gettextCatalog || angular.element(document.body).injector().get("gettextCatalog");
        this.clear();
    }

    resetItems( items = [] ) {
        this.clear();

        this.target_items = items;
    }

    clear() {
        this.target_items = [];
        this.search_result_views = {};
    }

    setResultView( visible, extended ) {
        
        this.default_search_result_view.visible = visible;
        this.default_search_result_view.extended = extended;

        for (let i in this.search_result_views) {
            this.search_result_views[ i ].visible = visible;
            this.search_result_views[ i ].extended = extended;
        }
    }

    getSearchResultViewOfItem( item ) {
        if (item[ this.track_by ]) {
            if (this.search_result_views[ item[ this.track_by ] ]) {
                return this.search_result_views[ item[ this.track_by ] ];
            }
        }

        let matchs = this.getSearchMatchOfItem( item ) ?? undefined;
        
        let strings = matchs ? this.buildSearchMatchStrings( matchs ) : undefined;

        this.search_result_views[ item[ this.track_by ] ] = {
            item,
            matchs,
            strings,
            visible:  this.default_search_result_view.visible,
            extended: this.default_search_result_view.extended,
        };

        return this.search_result_views[ item[ this.track_by ] ];
    }

    getSearchMatchOfItem( item ) {
        if (item.$$lastSearchMatchs) return item.$$lastSearchMatchs;
    }

    buildSearchMatchStrings( matchs ) {

        let strings = {};
        let indices = matchs[2];

        let key_i18n_label_mapping = {};

        for (let i in indices) {

            let key = indices[i].key, label = key_i18n_label_mapping[ key ];

            if (!label) {
                if (key.indexOf( "$search.$contains." ) == 0) {

                    let search_key = key.substring( 18 /* "$search.$contains.".length */ );

                    label = this.gettextCatalog.getStringIfExists( 'search_key:' + search_key ) || search_key;

                    key_i18n_label_mapping[ key ] = label;
                }
            }

            if (strings[ label ]) {
                strings[ label ] += "; ";
            } else {
                strings[ label ] = "";
            }

            strings[ label ] += `"${ indices[i].value }"`;
        }

        return strings;
    }
}


export function listPaginationSearchProvider() {

	var self = this;

	var FuseJS = window.Fuse || null;

	this.$get = [ "$rootScope", "$q", function( $rootScope, $q ) {
		return $q.when( FuseJS || jQuery.getScript( "/dashboard/node_modules/fuse.js/dist/fuse.js" ).then(function() {
			if (window.Fuse) FuseJS = window.Fuse;
		})).then(function() {

			if (!FuseJS) throw new Error( "Unable to load Fuse.js" );

			return fuseSearchFnGenerator;

		}).catch(function( error ) {

			console.error( error );

			return fallbackSearchFnGenerator;
		});
	}];

	function fallbackSearchFnGenerator( scope__all_items, options, scope ) {

		var ignore_search_word_replace = /^[a-zåäö]{1,2}$/;
		var whitespace_char_regexp = /\s/;

		function searchFn( scope__search_input ) {
			
			var items = null;

			/**
			 * Esijärjestetään teokset s.e. samalla haulla tulokseksi tulee sama tulos
			 * (Muuten saman hakupainoarvon saamat tulokset saattavat riippua mistä vain)
			 */
			var all_items = scope__all_items.sort(function( a, b ) {
				try {
					return (( a.uid || a.created_at || a.$hashKey ) + "").localeCompare(( b.uid || b.created_at || b.$hashKey ) + "");
				} catch(e) {}
			});

			var spls = stringToSearch( scope__search_input );
			console.log( "listPaginationDirective.onSearchChange :: Filtering with '"+ scope__search_input +"':", spls );

			if (spls.length > 0 && scope.search_opts) {

				items = all_items.map( doSearch );

			} else if (spls.length > 0 && scope.opts.search_str_key) {

				spls = spls.map(function( str ){
					return new RegExp( str );
				});

				items = all_items.map( doSearch_search_str_key );
			}

			if (items) {

				return items.filter(function( a ) {
					return a[0] > 0;
				}).sort(function( a, b ) {
					return b[0] - a[0];
				});
			}

			function doSearch( item ){
				var founds  = [];
				var weight  = 0;
				var fields = scope.search_opts.fields;

				var search = item[ scope.search_opts.key ] || {};

				for (var key in search.$contains) {
					var s = search.$contains[ key ];

					try {
						if (fields[ key ].selected) {
							for (var idx, i = 0; i < spls.length; i++) {
								idx = s.indexOf(spls[ i ]);

								if (idx != -1) {
									var w     = fields[ key ].weight || 1;
									var pchar = s[idx - 1];
									var nchar = s[idx + spls[i].length];
									pchar = pchar ? whitespace_char_regexp.test( pchar ) : true;
									nchar = nchar ? whitespace_char_regexp.test( nchar ) : true;

									if (pchar && nchar ) {
										w *= 2;
									} else if (pchar || nchar) {
										w *= 1.5;
									}

									founds.push([ key, w, spls[i], s ]);
									weight += w;
								}
							}
						}
					} catch(e) { console.warn( e ); }
				}

				return [ weight, founds, item ];
			}

			function doSearch_search_str_key( item ) {

				var founds = 0;

				for (var i in spls) {
					if (typeof spls[i] == "string" && item[ scope.opts.search_str_key ].indexOf(spls[i]) != -1) {
						founds++;
					} else if (typeof spls[i] == "object" && spls[i] instanceof RegExp && spls[i].test( item[ scope.opts.search_str_key ])) {
						founds++;
					}
				}

				return [ founds, [], item ];
			}

			function stringToSearch( str ) {
				return str.trim().toLowerCase()
				.split(/[\s]+/g)
				.filter(function( v ){
					return v.length > 0 && !ignore_search_word_replace.test( v );
				});
			}

		}

		return searchFn;
	}


	function newFuse( items, options ) {

		/************/
		options.ignoreLocation  = true; // Ei ole väliä miten kaukana alusta match löytyy
		options.ignoreFieldNorm = true; // Ei ole väliä löytyykö match pitkästä vai lyhyestä tuloksesta

		options.minMatchCharLength = 2;
		options.findAllMatches = true;

		//options.distance = 0;
		//
		options.useExtendedSearch = true;
		/************/

		console.time( "listPaginationSearchProvider::fallbackSearchFnGenerator.newFuse" );

		var fuse = new FuseJS( items, options );

		console.timeEnd( "listPaginationSearchProvider::fallbackSearchFnGenerator.newFuse" );

		return fuse;
	}

	function fuseSearchFnGenerator( _items, _options, scope ) {

		scope = scope || {};

		var items;
		var options;

		setItems( _items, _options );


		/**
		 * Halutaan tehdä ainakin seuraavanlaisia "erityishakuja":
		 *
		 * foo la bar asd
		 * -> ="foo la bar asd"
		 * -> '"foo la bar asd"
		 * -> 'foo 'bar 'asd
		 *
		 * foo la "bar asd"
		 * -> 'foo | '"bar asd"
		 *
		 */

		/** @see SPACE_RE @ https://github.com/krisk/Fuse/blob/master/dist/fuse.js */
		var splitter = / +(?=([^\"]*\"[^\"]*\")*[^\"]*$)/; // eg. /\s+/

		var __isNotExtendedMatcher = /^([^\^="']).*([^\$"])$/; // " Ei ala ^ eikä = eikä ' eikä " eikä lopu " tai $ "
		var __hfilsu = /^([^\^=']).*([^\$])$/; // " Ei ala ^ eikä = eikä ' eikä lopu $ "

		function searchFn( string, opts ) {

			if (!angular.isString( string )) string = "";

			string = string.trim();

			if (!string || string.length == 0) return null;

			var words = string.split( splitter ).map(function( v ) {

				// Siistitään whitespacet
				return v && v.trim();

			}).filter(function( v, i, arr ) {

				// Poistetaan splitterin luomat "tyhjät" elementit
				return v;

			}).filter(function( v, i, arr) {

				// Poistetaan duplikaatit
				return arr.indexOf( v ) == i;
			});

			if (words.length == 0) return null;

			var results = []; // Lopullinen tulos johon osatulokset concatataan


			results = _doInjectSearch(
				__gen_query_A( words, string ), // (foo bar) -> ="foo bar"
				results,
				2.5,
				result => { result[4] = "GroupA"; }
			);


			if (words.length > 1) {
				results = _doInjectSearch(
					__gen_query_B( words, string ), // (foo bar) -> '"foo bar"
					results,
					2,
					result => { result[4] = "GroupB"; }
				);
			}

			results = _doInjectSearch(
				__gen_query_C( words ), // (foo bar) => 'foo | 'bar
				results,
				1.3,
				result => { result[4] = "GroupC"; }
			);


			results = _doInjectSearch( __gen_query_D( words ), results, 1.2 );
			
			results = _doInjectSearch(                string,   results );


			return results.sort(function( a, b ) { return a[0] - b[0] });

			/**********
			 * Muutetaan ei-extended -haku (foo bar asd) muotoon ('"foo ba asd" | 'foo | 'asd)
			 */
			function __gen_query_A( words, string ) {
				
				for (var i in words) {
					if (!__isNotExtendedMatcher.test( words[i] )) return null;
				}

				return '="' + string + '"';
			}

			function __gen_query_B( words, string ) {

				for (var i in words) {
					if (!__isNotExtendedMatcher.test( words[i] )) return null;
				}

				return '\'"' + string + '"';
			}

			function __gen_query_C( words ) {

				var _$ors = [];

				for (var i in words) {

					var word = words[i];

					if (word.length >= 3 && __hfilsu.test( word )) {
						_$ors.push( "'" + word );
					}
				}

				return _$ors.join( " | " );
			}

			function __gen_query_D( words ) {

				var _$ors = [];

				for (var i in words) {

					var word = words[i];

					var MIN_LEN = 7;

					if (word.length > MIN_LEN /*options.minMatchCharLength*/ && __isNotExtendedMatcher.test( word )) {

						for (var j = word.length-1; j >= MIN_LEN; j--) {
							_$ors.push( "'" + word.substr( 0, j ));
							_$ors.push( "'" + word.substr( word.length - j ));

							for (var e = 0; e <= word.length - j; e++) {

								//_$ors.push( "'" + word.substr( e, j ));
							}
						}

						//_$ors.push( "'" + word.substr( 0, Math.ceil( word.length / 2 )));
						//_$ors.push( "'" + word.substr( Math.floor( word.length / 2 )));

						//_$ors.push( "^" + word.substr( 0, Math.floor( word.length / 2 )));
						//_$ors.push( word.substr( Math.ceil( word.length / 2 )) + "$" );
					}
				}

				return _$ors.join( " | " );
			}

			function _doInjectSearch( query, results, weight, callback ) {

				try {
				
					if (query) {

						var _results = _doSearch( query );

						if (weight) {
							for (var i in _results) {
								_results[i][0] = Math.pow( _results[i][0], weight );
							}
						}
				
						// results = __concat_without_duplicates( results, _results, 2 );

						results = __join_results( results, _results, callback );
					}
				} catch (error) {
					console.error( error );
				}

				return results;
			}

			function _doSearch( query ) {

				var results = doSearch( query, opts ).map(function( result ) {
					return [ result.score, result.matches, result.item, query ];
				});

				return results;
			}
		}

		function __join_results( source, target, callback ) {

			// Filtteröidään uusista tuloksista pois ne, joiden kohde on löytyy jo tuloksista
			return source.concat( target.filter(function( item ) {

				for (var i in source) {
					if (item[2] == source[i][2]) return false;
				}

				if (callback) try {
					callback( item );
				} catch (e) {}

				return true;
			}));
		}

		function __concat_without_duplicates( source, target, key ) {
			return source.concat( target.filter(function( item ) {
				for (var i in source) {
					if (item[key] == source[i][key]) return false;
				}

				return true;
			}));
		}


		var fuses_per_opts = {};

		function doSearch( string, opts ) {

			var opts_str = opts;

			if (!opts_str) opts_str = "{}";

			if (typeof opts_str == "object") opts_str = JSON.stringify( opts );


			if (!fuses_per_opts[ opts_str ]) {

				var nopts = angular.extend( angular.copy( options ), opts );

				fuses_per_opts[ opts_str ] = newFuse( items, nopts );
			}


			console.debug( "listPaginationSearchProvider::fallbackSearchFnGenerator.doSearch ("+string+"):: fuse[" , opts_str , "] =", fuses_per_opts[ opts_str ] );

			console.time( "listPaginationSearchProvider::fallbackSearchFnGenerator.doSearch("+string+")" );

			var results = fuses_per_opts[ opts_str ].search( string );

			console.timeEnd( "listPaginationSearchProvider::fallbackSearchFnGenerator.doSearch("+string+")" );
			
			console.debug( "listPaginationSearchProvider::fallbackSearchFnGenerator.doSearch ("+string+"):: results =", results );

			return results;
		}

		searchFn.setItems   = setItems;
		searchFn.setOptions = setOptions;

		function setItems( _items, _options ) {
			items = _items;

			if (_options != null) setOptions( _options );
			
			resetFuse();
		}

		function setOptions( _options ) {
		
			if (!_options) _options = {};

			_options.includeScore    = true;
			_options.includeMatches  = true;

			options = _options;
		}

		function resetFuse() {

			if (!items || !options) return;

			fuses_per_opts = {};
		}

		return searchFn;
	}
}

export default new Array( "$rootScope", "$timeout","$state","gettextCatalog","listPaginationSearch","searchHistory",
  function( $rootScope, $timeout,  $state,  gettextCatalog,  listPaginationSearch, searchHistory )
{


	return {
		scope: {
			"bridgeRef":            "=bridge",
			"paginationItemsRef":   "=items",
			"paginationOptionsRef": "=?options"
		},
		templateUrl: "/libs/dashboard/templates/directives/listPagination.html",
		link: function( scope, element, attributes ) {

			//window.DEV && console.profile( "listPaginationDirective.link" );

			var items = scope.paginationItemsRef || (attributes.paginationItems && JSON.parse( attributes.paginationItems )) || [];

			var opts = scope.opts = {};

			if (scope.paginationOptionsRef) {
				opts = scope.opts = scope.paginationOptionsRef;
			}

			if (attributes.paginationOptions) {
				opts = scope.opts = scope.paginationOptions = JSON.parse( attributes.paginationOptions );
			}

			scope.bridge = scope.bridgeRef || {};

			opts.defaults = opts.defaults || {};
			opts.defaults.items_per_page            =   typeof opts.defaults.items_per_page == "number" ? opts.defaults.items_per_page : 10;
			opts.defaults.pagination_current_page_i = parseInt(opts.defaults.pagination_current_page_i) || 0;
			opts.defaults.layout_type               =          opts.defaults.layout_type                || 'list';
			opts.defaults.sort_key                  =          opts.defaults.sort_key                   || null;
			opts.defaults.sort_order                =        !!opts.defaults.sort_order                 || true;

			scope.visible_items = null;
			scope.search_input              =          $state.params.search     || "";
			scope.current_sort_key          =          $state.params.sort_key   || opts.defaults.sort_key;
			scope.sort_order                =          $state.params.sort_o     || opts.defaults.sort_order;
			scope.layout                    =          $state.params.layout     || opts.defaults.layout_type;
			scope.items_per_page            = parseInt($state.params.items_c)   || opts.defaults.items_per_page;
			scope.pagination_current_page_i = parseInt($state.params.page_n)    || opts.defaults.pagination_current_page_i;
            scope.pagination_current_page_i = Math.max( 0, scope.pagination_current_page_i );


			if (typeof scope.opts.onSearchChange !== "function") scope.opts.onSearchChange = () => {};

			opts.packed = opts.packed == null && !!$state.params.packed;

			if (opts.search_key || opts.search_opts) {
				if (opts.search_str_key) console.warn( "Ignoring opts.search_str_key" );

				scope.search_opts = angular.extend({
					key    : opts.search_key,
					fields : {}
				}, opts.search_opts || {});

				opts.search_key = scope.search_opts.key;
			}

			scope.all_items = [];
			scope.items = [];
			for( var i in items ) {
				scope.all_items.push( items[i] );

				if (scope.search_opts) {
					try {
						var searchString = items[i][scope.search_opts.key];
						if (searchString) {
							for (var key in searchString.$contains) {
								if (!scope.search_opts.fields[ key ]) {
									scope.search_opts.fields[ key ] = {};
								}
							}
						}
					} catch(e) { console.warn(e); }
				}
			}
			scope.all_items_count = scope.all_items.length;

			if (scope.search_opts) {
				scope.search_opts.fuzzy_restriction = !!scope.search_opts.fuzzy_restriction;
				scope.search_opts.$$fields_count = Object.keys( scope.search_opts.fields ).length;
				scope.search_opts.$$selected_count = 0;

				if (scope.search_opts.$$fields_count > 0) {

					var search_params_fields = ($state.params.search_fields || null) && $state.params.search_fields.split(",");

					for (let key in scope.search_opts.fields) {
						var field = scope.search_opts.fields[ key ];

						if (search_params_fields) { // Tarkastetaan onko asetettu search paramseissa
							field.selected = search_params_fields.indexOf( key ) != -1;
						} else {
							field.selected = field.selected == null ? true : !!field.selected;
						}

						if (field.selected) scope.search_opts.$$selected_count++;						

						field.label    = field.label || gettextCatalog.getStringIfExists( "search_key:"+key ) || gettextCatalog.getStringIfExists( key );
					}
				} else {
					scope.search_opts = null;
				}
			}
			var search_opts = scope.search_opts;

			opts.show_all     = !!opts.show_all;
			opts.priority_key = opts.priority_key || 'priority';
			opts.sortable     = !!opts.sortable;
			if (opts.sortable) opts.show_all = true;

			scope.pagination_max_page_i = 0;
			scope.pagination_btn_arr = [];


			// scope.sort_order = true; // true: ASC, false: DESC
			if (typeof scope.sort_order == "string") {
				var sort_order = scope.sort_order.toUpperCase();
				
				scope.sort_order = true;

				// if (sort_order == "TRUE"  || sort_order == "ASC")  scope.sort_order = true;
				if (sort_order == "FALSE" || sort_order == "DESC") scope.sort_order = false;
			}

			scope.sortItemsBy    = sortItemsBy;

			/*******************
			 * Hakuhistorian ominaisuudet
			 */

			try {
				let context = `dashboard/${ $state.current.name }`;

				if ($rootScope.client_uid) {
					context = `dashboard/${ $rootScope.client_uid }/${ $state.current.name }`;
				}

				scope.searchHistory = searchHistory.getContext( context );

			} catch (error) {
				debugger;
				console.error( error );
			}

			/*********************
			 * Tagify-ominaisuudet
			 */
			try {
				// Oletuksena ei haluta Tagifyta käyttöön
				scope.tagifyOptions = null;

				var tagify = (window.innerWidth >= 768) && (opts.searchTagify || null);

				// Varmistetaan, että kyseessä ei ole tyhjät asetukset, turha alustaa jos on.
				if (tagify && Object.keys( tagify ).length > 0) {

					if (window.DEV) {
						console.warn( "Ei saa ottaa käyttöön testaamatta!!!" );

						scope.tagifyOptions = tagify;

						// tagifyDirective $emitoi tapahtuman, joten se nousee hierarkiassa ylöspäin -> ei tarvitse tarkistusta (??)
						scope.$on( "tagifyReady", function( evt, tagify ) {

							tagify.on( "keydown", function( e ) {
								/** @see {@link https://github.com/yairEO/tagify#faq} */
								if (
									!tagify.state.inputText &&  // assuming user is not in the middle oy adding a tag
									!tagify.state.editing       // user not editing a tag
								){

									if (e.detail.originalEvent.key == 'Enter') {
										scope.onSearchChange( scope.search_input );
									}
								}
							});
						});
					}
				}

			} catch (error) {
				console.error( error );
			}
			/***/

			scope.$on( 'refresh_pagination', function( event, attrs ) {
				scope.reloadItems();
			})

			scope.toggleItemSelection = function( item ){
				item.$selected = !item.$selected;
				//if (opts.packable && !opts.packed) {
				//	if (scope.getSelectedItems().length > 1) togglePacked( true );
				//}
			}
			scope.selectAll = function( v ){
				v = arguments.length == 0 ? true : !!v;
				scope.items.forEach(function(i){ i.$selected = v; });
			}
			scope.getSelectedItems = function(){
				scope.$$selected_items = scope.all_items.filter(function( item ){
					return item.$selected;
				});
				scope.$$selected_items_count = scope.$$selected_items.length;
				return scope.$$selected_items;
			}
		
			scope.togglePacked = togglePacked;
			scope.reloadItems = reloadItems;

			function togglePacked( val ) {
				opts.packed = (val == null ? !opts.packed : val);

				if (opts.packed) {
					
				} else {
					// scope.selectAll( false );
				}

				var is_on_search = scope.is_on_search;
				return refresh().then(function(){
					scope.is_on_search = is_on_search;
				});
			}

			function reloadItems() {
				console.log('reloaditems');

				scope.items = [];
				scope.bridge = scope.bridgeRef;
				items = scope.paginationItemsRef || JSON.parse( attributes.paginationItems );
				scope.all_items = [];
				for( var i in items ) scope.all_items.push( items[i] );
				scope.all_items_count = scope.all_items.length;
				scope.onSearchChange( undefined, true );
			}



			// TEMPLATE (url)
			opts.template = opts.template;
			opts.search_str_key = opts.search_str_key;

			// SORT METHODS (obj)
			if( !opts.sorts || Object.keys( opts.sorts ).length == 0 ) opts.sorts = null;

			for (let key in opts.sorts ||  []) {
				if ( typeof opts.sorts[ key ] == "string" ) {
					opts.sorts[ key ] = {
						label   : key,
						compare : opts.sorts[ key ]
					}
				}
				opts.sorts[ key ].key = key;

				if (typeof opts.sorts[ key ].compare == "string") {
					opts.sorts[ key ].compare = (0, eval)("(function(){return ("+ opts.sorts[ key ].compare +");})()"); // jshint ignore:line
				}

				opts.sorts[ key ].$i18n_label = gettextCatalog.getStringIfExists( "sort_key:"+key );
			}


			scope.sorts = opts.sorts;
			opts.defaults.sort_key = opts.defaults.sort_key || opts.sorts ? Object.keys(scope.sorts)[0] : null;
			scope.current_sort_key = scope.current_sort_key || opts.defaults.sort_key;

			if (scope.sorts) {
				Object.defineProperty( scope.sorts, "$$by_search", {
					value: { $i18n_label: gettextCatalog.getString( "sort_key:by_search" ) },
					configurable: true
				});
			}

			scope.searchResultsHandler = new SearchResultsHandler();

			init();
			function init() {

				//window.DEV && console.profileEnd( "listPaginationDirective.link" );

				$timeout(function() {

					if (!scope.search_opts) return;

					return listPaginationSearch.then(function searchFnBuilder( searchFnGenerator ) {

/*						var fuse_options = {
							//minMatchCharLength : 2,
							//useExtendedSearch  : true,
							keys               : []
						};

						if (scope.search_opts.fields) {

							fuse_options.keys = Object.keys( scope.search_opts.fields || {}).map(function( key ) {
								return {
									name:   "$search.$contains." + key,
									weight: isNaN( scope.search_opts.fields[ key ].weight ) ? 1 : scope.search_opts.fields[ key ].weight
								}
							});

						} else {

							for (var i in scope.all_items) {
								if (scope.all_items[i].$search) {
									for (key in scope.all_items[i].$search.$contains) {
										var nkey = "$search.$contains." + key;
										if (fuse_options.keys.indexOf( nkey ) == -1) {
											fuse_options.keys.push( nkey );
										}
									}
								}
							}
						}

						scope.searchFn = searchFnGenerator( scope.all_items, fuse_options, scope );
						*/
										
						/**
						 * https://artestnet.atlassian.net/browse/DASHBOARD-165
						 * Korvattu 24.1.2023, koska satojen ja tuhansien teosten tapauksessa käy äärimmäisen raskaaksi. (Miksi ylipäätään kopioidaan kauttaaltaan?)
						 * scope.searchFn = searchFnGenerator( angular.copy(scope.all_items), {}, scope );
						 */
	
						scope.searchFn = searchFnGenerator([ ...scope.all_items ], {}, scope );

					});

				}).then(function(){
					/*
						1/2. Järjestele kaikki
						1/2. Filtteröi turhat pois
						3. paginoi
					 */

					return $timeout(function(){
						var _inp = element.find("#list-pagination-directive-input-search");
						_inp[0] && _inp[0].addEventListener("keyup", function(e){
							if (e.keyCode == 13) scope.onSearchChange(_inp[0].value);
						});
						scope.onSearchChange( undefined, true );
					});
				});
			}

			// Funktiot
			function refresh() {

				window.DEV && console.profile( "listPaginationDirective.refresh" );

				scope.is_on_search = false;

				scope.visible_items = [];

				if (opts.show_all) {
					scope.pagination_current_page_i = 0;
					scope.items_per_page = scope.items.length;
				}

				scope.pagination_max_page_i = Math.floor( scope.items.length / scope.items_per_page ) ;
				if (scope.items.length % scope.items_per_page == 0) scope.pagination_max_page_i--;

				scope.pagination_current_page_i = Math.max( 0, Math.min( scope.pagination_current_page_i, scope.pagination_max_page_i ));

				for( var i = 0; i < scope.items_per_page; i++ )
					if (scope.items[ scope.items_per_page * scope.pagination_current_page_i + i ])
						scope.visible_items.push(scope.items[ scope.items_per_page * scope.pagination_current_page_i + i ]);

				return $timeout(function(){
					refreshPaginationButtons();


					try {
						/**
						 * Generoidaan hakuparametrit, joilla vastaavan haun voisi myöhemmin alustaa
						 */
						var search_params = {
							packed   : (opts.packed ? "true" : null),
							page_n   : (scope.pagination_current_page_i == opts.defaults.pagination_current_page_i) ? null : scope.pagination_current_page_i,
							sort_key : (scope.current_sort_key          == opts.defaults.sort_key)                  ? null : scope.current_sort_key,
							items_c  : (scope.items_per_page            == opts.defaults.items_per_page)            ? null : scope.items_per_page,
							search        : !!scope.search_input ? scope.search_input : null,
							search_fields : null,
							sort_o        : null
						};

						if (search_params.sort_key == "$$by_search") search_params.sort_key = null;

						if (search_opts && search_opts.fields && search_opts.$$fields_count != search_opts.$$selected_count) {
							search_params.search_fields = Object.keys( search_opts.fields ).filter(function(v){ 
								return search_opts.fields[ v ].selected;
							}).join( "," );
						}

						if (scope.sort_order == false) {
							search_params.sort_o = "false"; // "ASC"
						} else {
							// Oletus: search_params.sort_o = "DESC";
						}

						// Parametrit location.hash'iin
						$state.go(".", search_params, { notify : false });
					} catch (error) { console.error( error ); }
				
					window.DEV && console.profileEnd( "listPaginationDirective.refresh" );
				});
			}


			/******************************************/

			scope.sortableOptions = {
				axis: 'y',
				deactivate: function( event, ui ){
					var item = angular.element( ui.item ).scope().item;
					$timeout(function(){
						var priorities = scope.visible_items.map(function(v){ return v.$priority; }).sort(function( a, b ){ return a - b; });
						for( var i = 0; i < scope.visible_items.length; i++ ) scope.visible_items[ i ].$priority = priorities[ i ];
			
						scope.onSearchChange();
						if (typeof scope.bridge.onSortChanged == "function") scope.bridge.onSortChanged( item );
					});
				}
			};

			scope.refreshItemPriorities = refreshItemPriorities;
			function refreshItemPriorities(){
				var items = [].concat( scope.items );
				items.sort(function( a, b ){
					return a[ opts.priority_key ] - b[ opts.priority_key ];
				}).forEach(function( v, i ){
					v[ opts.priority_key ] = i;
				});
				scope.onSearchChange();
			}

			/* Onko tarpeen jäädä nykyiselle sivulle? */
			function sortItemsBy( key, sort_order ) {
				if (opts.sorts) {
					if ( typeof sort_order !== "undefined" ) scope.sort_order = !!sort_order;

					if ( typeof key !== "undefined" ) {
						if (opts.sorts[ key ]) scope.current_sort_key = key;
						else throw new Error("Sort-function for key '"+ key +"' not found");
					}

					if (opts.sorts[ scope.current_sort_key ] && typeof opts.sorts[ scope.current_sort_key ].compare == "function") {

						scope.items.sort(function( a, b ) {
							try {
								return opts.sorts[ scope.current_sort_key ].compare( a, b );
							} catch(e) { console.warn(e); }
						});
					}

					if (!scope.sort_order) { // Valittu käänteinen järjestys
						scope.items.reverse();
					}

					scope.items = [ ...scope.items ];
				}

				return refresh().then(function(){
				});
			}

			scope.setVisibleCount = (() => {
				
				let elem;

				const clear = () => {
						
					if (!elem) elem = document.querySelector( btn_selector );

					if (elem) {
						elem.classList.remove( "loading" );
					}
				};

				const btn_selector = '.list-pagination-directive-content .items_per_page-selector.dropdown';

				return count => {

					let _clear;

					if (count === scope.items.length) try {

						if (!elem) elem = document.querySelector( btn_selector );

						if (elem) {

							_clear = clear;

							elem.classList.add( "loading" );
						}
					} catch (e) {}


					setTimeout(() => {

						setVisibleCount( count );

						if (_clear) _clear();
					});
				};
			})();

			function setVisibleCount( count ) {

				count = Math.min( scope.items.length, Math.max( 0, parseFloat( count )));

				if (!count || isNaN(count)) return;
				
				if (count === scope.items.length) {
					togglePacked( true );
				}

				scope.items_per_page = count;

				// scope.pagination_current_page_i;

				goToPage( 0 );
			}

			scope.goToPage = goToPage;

			function goToPage( page_i, pplus ) {
				if ( page_i == null || typeof page_i == "undefined" )
					page_i = scope.pagination_current_page_i + pplus;

				if ( page_i >= 0 && page_i <= scope.pagination_max_page_i )
					scope.pagination_current_page_i = page_i;

				var is_on_search = scope.is_on_search
				return refresh().then(function(){
					scope.is_on_search = is_on_search;
				});
			}
			function refreshPaginationButtons() {
				var pagination_btn_arr_range = 5 - (scope.pagination_current_page_i > 5 ? 1 : 0) - (scope.pagination_current_page_i < scope.pagination_max_page_i-5 ? 1 : 0);
				scope.pagination_btn_arr = [];
				for( var i = Math.max(scope.pagination_current_page_i-pagination_btn_arr_range, 0);
				         i <= Math.min(scope.pagination_current_page_i+pagination_btn_arr_range, scope.pagination_max_page_i);
						 i++
					) scope.pagination_btn_arr.push(i);

				try {
					scope.pagination_btn_str = "";
					
					let gettext_scope = {
						type: {
							results: {
								length: scope.items.length < scope.items_per_page ? scope.items.length : scope.items_per_page
							},
							total_count:  scope.items.length
						}
					};

					scope.pagination_btn_str = gettextCatalog.getString( 'Showing {{ type.results.length }} of total {{ type.total_count }} results', gettext_scope ) || '';

				} catch (error) {}
			}

			scope.clearSearch = function clearSearch() {

				scope.search_input = "";

				scope.onSearchChange( "" );
			}

			scope.onSearchChange = function onSearchChange(str, notto0page) {

				window.DEV && console.profile( "listPaginationDirective.onSearchChange" );
				
				if ( !notto0page ) scope.pagination_current_page_i = 0;

				if (typeof str == "string") scope.search_input = str;
				else if (!!str) console.warn( "Invalid argument :: ignoring given search", str );

				var search_items;

				try {

					if (scope.search_input && angular.isFunction( scope.searchFn )) {

						var _search_opts = {};

						var available_keys = Object.keys( scope.search_opts.fields || {});
						var enabled_keys = available_keys.filter(function( key ) { return scope.search_opts.fields[key].selected; });

						if (true || available_keys.length != enabled_keys.length) {
							_search_opts.keys = enabled_keys.map(function( key ) {
								return {
									name:   "$search.$contains." + key,
									weight: scope.search_opts.fields[ key ].weight || 1
								}
							});
						}

						// search_items = scope.searchFn( scope.search_input, _search_opts );
				
						let search_input = scope.search_input;
						
						if (scope.search_opts.fuzzy_restriction) {
							let segments = splitSentencetoSegments( search_input );
							search_input = segments.map( v => `'"${ v }"` ).join( "|" );
						}
						
						search_items = scope.searchFn( search_input, _search_opts );
					}

				} catch (error) {
					console.error( error );
				}

				try {

					if (scope.search_input && scope.searchHistory)  {
						scope.searchHistory.put( scope.search_input );
					}

				} catch (error) {
					console.error( error );
				}

				scope.searchResultsHandler.clear();
			
				if (search_items) {
			
					console.log( "listPaginationDirective.onSearchChange :: Got result:", search_items );

					var track_by = "uid";
					for (var idx in scope.all_items) {
						if (scope.all_items[ idx ][ track_by ] == null) {
							console.warn( "There is an item whose track_id is null. Using whole object as identifier" );
							track_by = null;
							break;
						}
					}

					scope.items = search_items.map(function( a ) {

						if (a && a[1]) {
							Object.keys( a[1] ).forEach( i => {
								a[1][i].indices = a[1][i].indices.map(function( v ) {
									return v[2] = a[1][i].value.substr( v[0], v[1]-v[0]+1 );
								});
							});
						}

						var item;

						if (track_by == null) {

							item = a[2];

						} else {
							// property track_id löytyy kaikista all_items'eista, joten uskalletaan vertaillla

							for (var idx in scope.all_items) {
								if (scope.all_items[idx][ track_by ] == a[2][ track_by ]) {
									item = scope.all_items[idx];
									break;
								}
							}
						}

						if (!item) {

							console.warn( "Did not found search result from scope.all_items!" );

						} else {

							a[2] = item;
						}
						
						a[2].$$lastSearchMatchs = [ a[0], a[3], a[1], a[4] ];

						return a[2];
					});
				
					scope.searchResultsHandler.resetItems( scope.items );

					window.DEV && console.profileEnd( "listPaginationDirective.onSearchChange" );
					
					scope.opts.onSearchChange( scope.items );

					return refresh().then(function(){
						scope.current_sort_key = "$$by_search";
						scope.is_on_search = true;
					});

				} else {

					if (scope.current_sort_key == "$$by_search") scope.current_sort_key = opts.defaults.sort_key;

					scope.items = scope.all_items;
				
					window.DEV && console.profileEnd( "listPaginationDirective.onSearchChange" );

					scope.opts.onSearchChange( scope.items );

					return sortItemsBy();
				}
			};


			scope.searchFieldSelect = function( key, select ) {
				if (!scope.search_opts.fields[key]) return;

				if (select == null) select = !scope.search_opts.fields[key].selected;
				scope.search_opts.fields[key].selected = !!select;

				scope.search_opts.$$selected_count = 0;
				for (var _key in scope.search_opts.fields) if (scope.search_opts.fields[_key].selected) scope.search_opts.$$selected_count++;
			};


			scope.toggleSearchFieldsSelect = function toggleSearchFieldsSelect() {
				var select = true;
				scope.search_opts.$$selected_count = 0;

				for (let key in scope.search_opts.fields) if (scope.search_opts.fields[key].selected) scope.search_opts.$$selected_count++;

				// Jos kaikki valittu, poistetaan valinnat, muulloin valitaan kaikki
				if (Object.keys( scope.search_opts.fields ).length == scope.search_opts.$$selected_count) {
					select = false;
				}

				scope.search_opts.$$selected_count = select ? Object.keys( scope.search_opts.fields ).length : 0;

				for (let key in scope.search_opts.fields) scope.search_opts.fields[key].selected = select;
			};
		}
	}
});
